import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  Input,
} from "../../components/Core";

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Section bg="dark" className="position-relative">
      <Container>
        <Row className="justify-content-center">
          <Col lg="7" xl="6">
            <Box mb={5} className="text-center">
              <Title color="light">Contact</Title>
              <Text color="light" opacity={0.7}>
              See what success looks like today
              </Text>
            </Box>
            <Link to="/contact/">
              <Button width="100%" type="submit" borderRadius={10}>
                Start Your Transformation
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CTA;
